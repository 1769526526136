@font-face {
    font-family: exo;
    src: url('../public/fonts/Exo-ExtraLight.ttf');
}  
@font-face {
    font-family: tinos;
    src: url('../public/fonts/Tinos-Regular.ttf');
}

$font-headers: exo;
$font-regular: tinos;

$color-special: #7D53DE;
$color-special-second: #d73269;
$color-background: #5C5C5C;
$color-background-dark: #141414;
$color-background-special: #030B12;
$color-text: #D0CDC8;
$color-bright: #ffffff;
$color-tile: rgb(15, 15, 15);
$color-tile-hover: rgb(30, 30, 30);
$color-shadow: rgba(0, 0, 0, 0.1);
$color-shadow-light: rgba(255, 255, 255, 0.1);

* {
    text-align: center;
    scroll-behavior: smooth !important;
    font-display: fallback;
}

body {
  box-sizing: border-box;
  overflow: hidden !important;
  position: relative;
}

.App {
  background-color: $color-background-dark;
}

.homeParallax {
  background-image: url('../public/img/particles-black.gif');
  height: 100vh;
}

.certificatesParallax {
  background-image: url('../public/img/black-wall.webp');
}

.container {
  width: 100vw;
}

.certificate {
  border: solid 1rem transparent;
  transition: .5s ease-out; 
}

.certificate:hover {
  padding: .3rem !important;
  box-shadow: inset 0 0 1rem .5rem $color-special !important;
}

.certificate:after {  
  content: "";
  height: 70px;
  width: 1px;  
  position: absolute;  
  left: -1px;
  top: 65%;
  transition: top, opacity;
  transition-duration: 600ms;
  transition-timing-function: ease;
  background: linear-gradient(
    transparent,
    $color-bright,
    transparent
  );
  opacity: 0;
}

.certificate:hover:after {
  top: 25%;
  opacity: 1;
}

.about-link {
  text-decoration: none !important;
  text-shadow: 0 0 .5rem $color-background-dark;
  pointer-events: all !important;
  color: $color-special;
  transition: 100ms ease-out;
}

.about-link:hover {
  color: $color-special-second;
}

.about-link:active {
  color: $color-bright;
}

.card .img {
  aspect-ratio: 0.77;
  opacity: .5;
  transition: 500ms ease-in-out;
}

.card {
  background-color: $color-background-dark;
  border: solid 2rem transparent;
  background-clip: content-box;
}

.card .img:hover {
  opacity: .7 !important;
}

.cert-cards {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
  display: flex;
  flex-wrap: wrap;
}

.caption, .about-caption {
  font-family: $font-headers;
  color: $color-text;
  font-size: clamp(1rem, calc(1rem + 2vh + 3vw), 10rem);
  background-color: transparent;
  margin: 0;
  padding: 1rem;
  position: inherit;
}

.about-caption {
  position: relative;
  margin: 1rem 0 !important;
  padding: 0 !important;
  width: 25vw !important;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1200px) {
    line-height: clamp(2rem, calc(1rem + 2vh + 1vw), 3rem) !important;
  }
  @media screen and (max-width: 400px) {
    margin: 0 !important;
  }
}

.about-me {
  font-family: $font-regular;
  font-size: clamp(.5rem, calc(.5rem + .5vh + 1vw), 5rem);
  color: $color-text;
  transition: opacity 1200ms ease;
  @media screen and (max-width: 1200px) {
    width: 95vw;
    padding: 0;
  }
  @media screen and (max-width: 700px) {
    line-height: clamp(.5rem, calc(.5rem + 2vh + 1vw), 2rem);
    width: 85vw;
  }
  line-height: clamp(2rem, calc(.5rem + 2vh + 1vw), 5rem);
  width: 75vw;
  border: solid .1rem $color-special;
  box-shadow: 2px 2px 2px $color-shadow;
  padding: 2rem;
}

.about-caption, .about-me {
  backdrop-filter: blur(1px);
  border-radius: 1rem;
}

.homeParallax, .certificatesParallax {
  position: relative;
  background-attachment: fixed;
  background-size: cover;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

.Typewriter {
  color: $color-special;
  position: absolute;
  font-size: calc(1rem + 4vh + 8vw) !important;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}

.top-caption {
  font-family: $font-headers;
  color: $color-text;
  position: absolute;
  font-size: calc(1rem + 2vh + 3vw) !important;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}

.bot-caption {
  font-family: $font-headers;
  color: $color-text;
  position: absolute;
  font-size: calc(1rem + 1vh + 1.5vw) !important;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}

.anchor {
  background-color: transparent !important;
  transition: all 400ms ease !important;
  font-family: $font-headers;
  color: $color-bright !important;
  text-decoration: none;
}

.anchors:has(.anchor) {
  font-family: $font-headers;
  font-size: clamp(.5rem, calc(.5rem + 1vw + 2vh), 4rem);
  z-index: 2;
  background-image: radial-gradient(
    $color-shadow-light 9%,
    transparent 9%
  );
  background-position: 0% 0%;
  background-size: 4vmin 4vmin;
}

.anchors:has(.anchor:hover) {
  background-size: 3.6vmin 3.6vmin;
}

.anchors[data-active-index='0'] {
  background-position: 0% -25%;
}

.anchors[data-active-index='1'] {
  background-position: 0% -50%;
}

.anchors[data-active-index='2'] {
  background-position: 0% -75%;
}

.anchors[data-active-index='3'] {
  background-position: 0% -100%;
}

.bm-burger-button {
  background: transparent;
  position: fixed;
  width: 2rem;
  height: 2rem;
  left: 2rem;
  top: 2rem;
  @media screen and (max-width: 500px) {
    left: 1rem;
    top: 1rem;
  }
}

.bm-burger-bars {
  background: transparent;
  padding: .1rem;
  box-shadow: 0 0 0 .05rem $color-bright, 0.15rem 0.15rem .5rem $color-shadow !important;
  border-radius: .5rem;
  opacity: .2;
  transition: .3s;
  transition: all 90ms ease-in-out;
}

.bm-burger-bars-hover {
  opacity: 1 !important;
  border-radius: 1px;
  box-shadow: 0 0 0 .1rem $color-special, 0.15rem 0.15rem .5rem $color-shadow  !important;
  padding: .1rem;
}

.bm-burger-button:active {
  .bm-burger-bars {
    border-radius: .2rem;
    margin-right: .1rem !important;
    margin-left: .1rem !important;
    box-shadow: 0 0 0 .15rem $color-special-second, 0.15rem 0.15rem .5rem $color-shadow  !important;
  }
}

.bm-cross-button:hover {
  .bm-cross {
    box-shadow: 0 0 0 .05rem $color-bright !important;
  }
}

.bm-cross-button:active {
  .bm-cross {
    box-shadow: 0 0 0 .1rem $color-bright !important;
  }
}

.bm-cross {
  background: $color-special;
}

.bm-menu-wrap {
  transition: .3s ease-out !important;  
  border: solid .1rem $color-special;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: $color-background-dark;
  min-width: 20rem !important;
}

.bm-menu {
  padding-top: 20vh;
  text-align: center;
}

.bm-item-list {
  height: auto !important;
  transition: opacity 1000ms ease !important;
}

.bm-item {
  padding: 2vh;
  transition: opacity 400ms ease;
}

.bm-item-list > .bm-item {
  transition: 300ms ease !important;
}

.bm-item-list:hover > .bm-item {
  opacity: 0.3;
}

.bm-item-list:hover > .bm-item:hover {
  opacity: 1;
}

#outer-container {
  height: 100vh;
}

#page-wrap {
  position: absolute;
  overflow-x: hidden;
  height: 100%;
}

@keyframes background-pan {
  from {
    background-position: 0% bottom;
  }
  
  to {
    background-position: -200% top;
  }
}

.body {
  animation: background-pan 7s linear infinite;
  background: linear-gradient(
    to right,
    $color-special,
    $color-special-second,
    $color-special
  );
  background-size: 200%;
  height: 100vh;
  overflow: hidden;
  margin: 0px;
  position: relative;
  .toggled {
    animation: none;
    > #tiles > .tile:hover {
      opacity: 0.1 !important;
    }
  }
}

.centered {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  pointer-events: none;
}

.body .centered {
  position: absolute;
  top: 0%;
}

#tiles {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: repeat(var(--rows), 1fr);
}

.tile {
  cursor: pointer;
  position: relative;
}

.tile:before {
  background-color: $color-tile;
  content: "";
  inset: 0.5px;
  position: absolute;
}

.tile:hover:before {
  background-color: $color-tile-hover;
}

.footer {
  align-items: center;
  @media screen and (min-width: 576px) {
    display: flex;
    justify-content: center;
  }
  box-shadow: inset 0 1px 0 1px $color-special;
  transition: 500ms ease;
}

.footer:has(.contact-info:hover) {
  box-shadow: inset 0 1px 0 1px $color-special-second;
}

.contact-icon {
  color: $color-special;
  margin: 1rem;
  transition: 100ms ease-out;
}

.contact-icon:hover {
  color: $color-special-second;
}

.contact-icon:active {
  color: $color-bright;
}

.contact-info {
  font-family: $font-regular;
  font-size: clamp(8px, calc(8px + 1vw) , 12px);
  color: $color-special;
  border: solid 1px $color-special;
  border-radius: .5rem;
  transition: 300ms ease;
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.contact-info:hover {
  background-color: $color-special-second;
  border: solid 1px $color-bright;
  color: $color-bright;
}

.contact-info:active {
  background-color: $color-bright;
  color: $color-background-special;
  border: solid 1px $color-special-second;
}